<template>
  <div class="list-content">
    <div class="list-header">
      <div class="text">
        {{ list.name }}
        <q-badge v-if="durationMinutesTotalUnbilled > 0 && user && [1,3,11].includes(user.id)"
                 :color="'green-5'"
                 :label="`к оплате: `+(durationMinutesTotalUnbilled / 60).toFixed(2)"></q-badge>
        <q-badge v-if="durationMinutesTotal > 0 && user && [1,3,11].includes(user.id)"
                 :color="'grey-5'"
                 :label="'всего: '+(durationMinutesTotal / 60).toFixed(2)"></q-badge>
      </div>
      <div class="actions">
        <q-btn flat padding="none" icon="add" @click="this.$emit('btn-new-task-click', list.id);"/>
      </div>
    </div>
    <draggable
        :data-id="list.id"
        class="list-group"
        ghost-class="ghost"
        :list="list.tasks"
        group="column"
        itemKey="name"
        animation="150"
        @end="onReorderEnds"
    >
      <template #item="{ element, index }">
        <div class="element-card"
             :data-id="element.id"
             :data-name="element.name"
             @contextmenu.prevent.stop="handleClick($event, element)"
             @click="this.$emit('edit-single-task', element)"
             v-if="true || element.group_id != 45 || (element.group_id == 45 && (!element.settings || !JSON.parse(element.settings).invoice_status))">
          {{ element.name }}
          <span v-if="false && user && [3].includes(user.id)">
<!--            <q-badge v-if="element.works_duration_minutes"
                     :color="'grey-4'"
                     :label="durationMinutesToString(element.works_duration_minutes)"/>-->
            <q-badge v-if="element.works_duration_minutes"
                     :color="'blue-2'"
                     :label="(element.works_duration_minutes/60).toFixed(2)"/>
<!--            <q-badge v-if="element.works_duration_minutes"
                     :color="'grey-4'"
                     :label="durationMinutesToString(element.works_client_duration_minutes)"/>-->
            <q-badge v-if="element.works_duration_minutes"
                     :color="'grey-4'"
                     :label="(element.works_client_duration_minutes/60).toFixed(2)"/>
          </span>
          <div style="display: flex; flex-direction: row; justify-content: flex-start; width: 100%; gap: 8px;">
            <div style="display: flex; align-items: center; gap: 8px;">
              <div v-if="element.settings && JSON.parse(element.settings).invoice_status">
                <q-icon name="currency_ruble"
                        :color="$settings.getInvoiceStatusColor(JSON.parse(element.settings).invoice_status).color"
                        size="xs"/>
              </div>

              <div v-if="element.group_id == 45 && (!element.settings || !JSON.parse(element.settings).invoice_status)">
                <q-badge
                  color="green">
                  <q-icon name="currency_ruble"
                          :color="white"
                          size="xs"/>
                </q-badge>
              </div>

              <div v-if="element.settings && JSON.parse(element.settings).priority">
                <q-icon name="flag"
                        :color="$settings.getPriorityFlagColor(JSON.parse(element.settings).priority).color"
                        size="xs"/>
                <q-tooltip :delay="330" anchor="top middle" self="bottom middle">
                  {{ $settings.getPriorityFlagColor(JSON.parse(element.settings).priority).name }}
                </q-tooltip>
              </div>

              <div v-if="element.description">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_530_119)">
                    <path d="M11 5H1V6H11V5ZM11 2H1V3H11V2ZM1 9H7V8H1V9Z" fill="#999"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_530_119">
                      <rect width="12" height="12" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <q-tooltip :delay="330">
                  У карточки есть описание
                </q-tooltip>
              </div>
              <div v-if="element.commnets_count > 0">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_530_107)">
                    <path d="M2 8.585L2.585 8L9 8L9 3L2 3V8.585ZM2 2L9 2C9.55 2 10 2.45 10 3L10 8C10 8.55 9.55 9 9 9L3 9L1 11V3C1 2.45 1.45 2 2 2Z" fill="#999"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_530_107">
                      <rect width="12" height="12" fill="white" transform="matrix(-1 0 0 1 12 0)"/>
                    </clipPath>
                  </defs>
                </svg>
                <small>{{element.commnets_count}}</small>
                <q-tooltip :delay="330">
                  У карточки есть комментарии
                </q-tooltip>
              </div>
              <div v-if="element.attachments_count > 0">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_540_124)">
                    <path d="M5.5 4V7.5C5.5 7.5 5.5 8.5 6.5 8.5C7.5 8.5 7.5 7.5 7.5 7.5V3.5C7.5 3.5 7.5 1.5 5.5 1.5C3.5 1.5 3.5 3.5 3.5 3.5V7.5C3.5 7.5 3.5 10.5 6.5 10.5C9.5 10.5 9.5 7.5 9.5 7.5V3" stroke="#999"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_540_124">
                      <rect width="12" height="12" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <small>{{element.attachments_count}}</small>
                <q-tooltip :delay="330">
                  У карточки есть вложения
                </q-tooltip>
              </div>
              <div v-if="element.due_date" style="opacity: 0.5; font-size: 12px; justify-self: start">
              <span>{{ $dayjs(element.due_date).format($dayjs.cf.S) }}</span>
            </div>
            </div>
            <div style="display: flex; margin-left: auto;">
              <q-badge v-if="element.works_duration_minutes"
                       style="align-self: center;"
                       :color="'white'"
                       :text-color="'grey-5'"
                       :label="durationMinutesToString(element.works_client_duration_minutes)"/>

              <div
                  v-if="element.assignee"
                  style="background-color: #ccc; width: 24px; height: 24px; border-radius: 30px; display: flex; justify-content: center; align-items: center; margin-left: 8px;">
                <q-img
                    v-if="userList.filter(obj => { return obj.id === element.assignee })[0]?.avatar"
                    :src="userList.filter(obj => { return obj.id === element.assignee })[0]?.avatar"
                    spinner-color="white"
                    style="width: 24px; height: 24px; border-radius: 100%;"
                >
                </q-img>
                <span
                    v-else>{{element.assignee_name?.split(' ').map(word => word.charAt(0)).join('')}}</span>
                <q-tooltip :delay="330">
                  {{ element.assignee_name }}
                </q-tooltip>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <q-btn size="sm"
               flat
               padding="xs"
               label="задача"
               icon="add"
               color="standard"
               @click="this.$emit('btn-new-task-click', list.id);"
               style="margin-left: auto"/>
      </template>
    </draggable>
  </div>

  <!-- Make sure you add the `ref` attribute, as that is what gives you the ability
  to open the menu. -->
  <vue-simple-context-menu
      element-id="myUniqueId"
      :options="options"
      ref="vueSimpleContextMenu"
      @option-clicked="optionClicked"
  />
</template>

<script>
import draggable from "vuedraggable/src/vuedraggable";
import { duration } from '@/helpers/duration';
import { task } from '@/api/task'
import { mapGetters, mapMutations } from 'vuex'
import VueSimpleContextMenu from 'vue-simple-context-menu'
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'
// import { user } from '../../api/user'

export default {
  name: "BoardColumn",
  components: {
    draggable,
    VueSimpleContextMenu,
  },
  emits: ["edit-single-task", "reorder-ends", "btn-new-task-click"],
  props: {
    // список задач (на самом деле это tasks)
    // taskList: [],
    // сам элемент списка (колонка на доске)
    list: {}
  },
  watch: {
    list(val) {
      this.calcDurationMinutesTotal();
    }
  },
  data() {
    return {
      userList: this.$settings.userList,
      durationMinutesTotal: 0,
      durationMinutesTotalUnbilled: 0,
      options: [{name: 'Копировать название'}],
    }
  },
  created() {
    // Pass a value to the parent through the function
    this.calcDurationMinutesTotal();
  },
  computed: {
    ...mapGetters({
      user: "account/user",
    }),
    // user () {
      // return user
    // },
    // cards() {
    //   const cardFilteredByListId = this.$store.getters["cards"];
    //   return cardFilteredByListId.filter((card) => {
    //     if (card.listId === this.listId) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    ...mapGetters({
    }),
  },
  methods: {
    durationMinutesToString(minutes) {
      return duration.minutesToString(minutes);
    },
    onReorderEnds: function (e) {
      // console.log('item__'+ e.item.getAttribute('data-id') +' --> '+ 'to__' + e.to.getAttribute('data-id')+ '  sort__' + e.newIndex);
      this.$emit('reorder-ends');
      this.updateTask({
        name: e.item.getAttribute('data-name'),
        id: e.item.getAttribute('data-id'),
        group_id: e.to.getAttribute('data-id'),
        sort: e.newIndex,
      });
    },
    async updateTask (options) {
      try {
        const result = await task.update({
          id: options.id,
          group_id: options.group_id,
          sort: options.sort,
          // TODO: не должно в этом месте быть name
          name: options.name,
        });
      }
      catch (error) {
        if (error.response.status !== 404) {}
      }
      finally {}
    },
    async calcDurationMinutesTotal() {
      let _this = this;

      this.durationMinutesTotal = 0;
      this.durationMinutesTotalUnbilled = 0;

      if(Object.keys(this.list.tasks).length > 0) {
        this.list.tasks.forEach(function (item) {
          if (item.works_client_duration_minutes) {
            _this.durationMinutesTotal += item.works_client_duration_minutes;

            // суммируем только невыставленное
            if (!item.settings || !JSON.parse(item.settings).invoice_status) {
              _this.durationMinutesTotalUnbilled += item.works_client_duration_minutes;
            }
          }
          // console.log(item.works_client_duration_minutes)
        });
      }

      // this.durationMinutesTotal = (145 / 60).toFixed(2);
    },

    handleClick (event, item) {
      console.log('222')
      this.$refs.vueSimpleContextMenu.showMenu(event, item)
    },
    optionClicked (event) {
      window.alert(JSON.stringify(event))
    }
  }
}
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.3;
  background: #bde6fc !important;
}
.not-draggable {
  cursor: no-drop;
}
.list-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  margin: 20px 0;
  font-weight: 700;

  .text {}

  .actions {}
}
.list-card {
  position: relative;
  display: flex;
  flex-direction: column;
  //min-width: 280px;
  height: auto;
}

.list-content {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  //min-width: 280px;
  //max-width: 280px;
  width: 100%;
  height: auto;
  //background-color: rgb(232, 239, 243);
  background-color: rgb(249, 252, 252);
  padding: 0 10px 0 10px;
  box-shadow: 1.5px 1.5px 1.5px 0.1px rgba(255, 255, 255, 0.1);
  color: rgba(24, 43, 77, 1);
}

.list-group {
  //border: 1px dashed #ccc;  // !!!
  height: 100%; // чтобы перетаскивать в него

  //margin-right: -10px;

  min-height: 0;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;

  -webkit-overflow-scrolling: touch;
  scrollbar-color: var(--ds-background-neutral-hovered,#091e4224) var(--ds-background-neutral,#091e420f);
  scrollbar-width: thin;
  -webkit-transform: translateZ(0);

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track-piece {
    background: #e5e5e6;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #cfcfd1;
  }
}

.list-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 280px;
  background-color: rgba(235, 236, 240, 1);
  border-radius: 0 0 10px 10px;
  color: white;
  border-top: 0.5px solid rgba(255, 255, 255, 0.25);
  padding: 0 10px 10px 10px;
}

.input-new-list {
  display: flex;
  height: 30px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(235, 236, 240, 0.5);
  min-width: 260px;
}

.input-new-list::placeholder {
  color: white;
}

.element-card {
  position: relative;
  background-color: white;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-radius: 8px;
  min-height: 30px;
  margin-bottom: 10px;
  text-align: left;
  box-shadow: var(--ds-shadow-raised,0 1px 8px #091e4208);
  cursor: pointer;

  border: 1px solid #dde1e3;
  transition: border-color 0.15s linear;

  &:hover {
    box-sizing: border-box;
    //background-color: #f8f8f8;
    border: 1px solid #81949a;
    //box-shadow: var(--ds-shadow-raised,0 2px 8px #091e4220);
  }
}

.vue-simple-context-menu {
  &--active {
  }

  &__item {
    &:hover {
    }
  }

  &__divider {
  }
}
</style>
