
import './styles/quasar.scss'
import '@quasar/extras/material-icons/material-icons.css'
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import Notify from 'quasar/src/plugins/Notify.js';
import Dialog from 'quasar/src/plugins/Dialog.js';
import langRu from 'quasar/lang/ru'

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: [Notify, Dialog],
  lang: langRu
}
