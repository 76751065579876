<template>
  <q-dialog transition-show="fade" transition-hide="fade" transition-duration="200">
    <q-card style="width: 900px; max-width: 80vw;">
      <q-card-section class="q-pa-lg">

        <div class="q-gutter-x-md --row --items-start">
          <div class="row">
            <q-input
                filled
                dense
                disable
                v-model="startDate"
                label="Дата"
                style="width: 160px"
            />
            <q-btn icon="event" round>
              <q-popup-proxy cover transition-show="scale" transition-hide="scale" ref="qDateProxy">
                <q-date
                    today-btn
                    v-model="startDate"
                    mask="YYYY-MM-DD"
                    minimal
                    @update:model-value="$refs.qDateProxy.hide()"/>
              </q-popup-proxy>
            </q-btn>
          </div>

          <div class="row" style="margin-top: 20px;margin-bottom: 20px;">
            <div class="row">
              <q-input
                  filled
                  dense
                  label="Длительность, мин"
                  v-model="newJobDurationString"
              />
              <q-btn-dropdown outline padding="xs md">
                <div class="row">
                  <q-list>
                    <q-item clickable v-close-popup @click="updateDuration('15м')">
                      <q-item-section>
                        <q-item-label>15 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDuration('20м')">
                      <q-item-section>
                        <q-item-label>20 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDuration('30м')">
                      <q-item-section>
                        <q-item-label>30 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDuration('40м')">
                      <q-item-section>
                        <q-item-label>40 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDuration('45м')">
                      <q-item-section>
                        <q-item-label>45 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDuration('1ч')">
                      <q-item-section>
                        <q-item-label>1 час</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                  <q-list>
                    <q-item clickable v-close-popup @click="updateDuration('1ч')">
                      <q-item-section>
                        <q-item-label>1 час</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDuration('1ч 30м')">
                      <q-item-section>
                        <q-item-label>1 час 30 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDuration('2ч')">
                      <q-item-section>
                        <q-item-label>2 часа</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDuration('2ч 30м')">
                      <q-item-section>
                        <q-item-label>2 часа 30 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDuration('3ч')">
                      <q-item-section>
                        <q-item-label>3 часа</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDuration('4ч')">
                      <q-item-section>
                        <q-item-label>4 часа</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
            </q-btn-dropdown>
            </div>

            <div v-if="user && [1,3].includes(user.id)" class="row">
              <q-input
                  filled
                  dense
                  label="ДлительностьК, мин"
                  v-model="newJobDurationClientString"
                  style="margin-left: 30px;"
              />
              <q-btn-dropdown outline padding="xs md">
                <div class="row">
                  <q-list>
                    <q-item clickable v-close-popup @click="updateDurationClient('15м')">
                      <q-item-section>
                        <q-item-label>15 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDurationClient('20м')">
                      <q-item-section>
                        <q-item-label>20 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDurationClient('30м')">
                      <q-item-section>
                        <q-item-label>30 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDurationClient('40м')">
                      <q-item-section>
                        <q-item-label>40 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDurationClient('45м')">
                      <q-item-section>
                        <q-item-label>45 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDurationClient('1ч')">
                      <q-item-section>
                        <q-item-label>1 час</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                  <q-list>
                    <q-item clickable v-close-popup @click="updateDurationClient('1ч')">
                      <q-item-section>
                        <q-item-label>1 час</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDurationClient('1ч 30м')">
                      <q-item-section>
                        <q-item-label>1 час 30 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDurationClient('2ч')">
                      <q-item-section>
                        <q-item-label>2 часа</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDurationClient('2ч 30м')">
                      <q-item-section>
                        <q-item-label>2 часа 30 минут</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDurationClient('3ч')">
                      <q-item-section>
                        <q-item-label>3 часа</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="updateDurationClient('4ч')">
                      <q-item-section>
                        <q-item-label>4 часа</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </q-btn-dropdown>
            </div>
          </div>

<!--          <div class="row" style="width: 100%; margin-top: 20px;">
            <q-input
                filled
                dense
                v-model="newJobName"
                label="Что сделано"
                style="width: 100%"
                :rules="[ val => val.length <= 255 || 'Больше 255: пиши в описание']"
            />
          </div>-->

          <div class="row" style="margin-top: 20px;margin-bottom: 20px;">
            <q-input
                v-show="user && [1,3].includes(user.id)"
                filled
                dense
                v-model="newJobAssigneeId"
                label="Автор"
                style="width: 100px"
            />
            <q-btn @click="newJobAssigneeId=3"
                   v-if="user && [1,3].includes(user.id)">Стас</q-btn>
            <q-btn @click="newJobAssigneeId=9"
                   v-if="user && [1,3].includes(user.id)">Юра</q-btn>
            <q-btn @click="newJobAssigneeId=10"
                   v-if="user && [1,3].includes(user.id)">Илья</q-btn>
            <q-btn @click="newJobAssigneeId=1"
                   v-if="user && [1,3].includes(user.id)">Алексей</q-btn>

          </div>

          <div>
<!--            <q-input
                v-if="user && [1,3].includes(user.id)"
                filled
                dense
                v-model="newJobDescription"
                label="Что descr"
                style="width: 100%"
            />-->
          </div>

          <ckeditor :editor="editor"
                    v-model="newJobDescription"
                    :config="editorConfig"
                    style="height: 200px;"
          ></ckeditor>

          <div class="row" style="margin-top: 20px;">
            <q-btn type="submit" color="primary"
                   :label="workCreationInProgress ? 'Трекаю, подождите...' : 'Добавить работу'"
                   :disabled="!(newJobDurationMinutes > 0)||workCreationInProgress||!newJobAssigneeId||newJobDescription==''"
                   @click="createWork()"/>
          </div>

        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import { duration } from '@/helpers/duration'
import { work } from '@/api/work'
import { mapGetters } from 'vuex'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'WorkAddModal',
  components: {
    ckeditor: CKEditor.component
  },
  emits: ["ok", "hide"],
  props: {
    taskId: null,
    projectId: null,
  },
  computed: {
    ...mapGetters({
      user: "account/user",
    }),
  },
  setup() {
    return {
      //date picker
      startDate: ref('YYYY-MM-DD'),
    }
  },
  watch: {
    newJobDurationString(val) {
      this.updateNewJobDurationMinutes();
    },
    newJobDurationClientString(val) {
      this.updateNewJobDurationMinutes();
    },
  },
  data() {
    return {
      // taskId: null,
      // projectId: null,
      newJobName: null,
      newJobDescription: null,
      newJobAssigneeId: null,
      workCreationInProgress: false,

      newJobDurationString: '',
      newJobDurationMinutes: 0,
      newJobDurationMinutesBackToString: ' ',
      newJobDurationClientString: '',
      newJobDurationMinutesClient: 0,
      newJobDurationMinutesClientBackToString: ' ',

      editor: ClassicEditor,
      editorConfig: {
        ckfinder: {
          uploadUrl: 'https://api.vponedelnik.ru/attachment/create/',
          headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem("minutka-app"))?.token}`}
        },
      },
    }
  },
  created () {
    // this.$watch(
    //     () => this.user,
    //     { immediate: true }
    // )

    this.newJobAssigneeId = this.user.id;
  },
  methods: {
    // TODO: дубль
    updateNewJobDurationMinutes() {
      this.newJobDurationMinutes = duration.stringToMinutes(this.newJobDurationString)
      this.newJobDurationMinutesBackToString = duration.minutesToString(this.newJobDurationMinutes);
      //
      this.newJobDurationMinutesClient = duration.stringToMinutes(this.newJobDurationClientString)
      this.newJobDurationMinutesClientBackToString = duration.minutesToString(this.newJobDurationMinutesClient);
    },
    updateDuration(minutes) {
      this.newJobDurationString = minutes;
    },
    updateDurationClient(minutes) {
      this.newJobDurationClientString = minutes;
    },
    async createWork() {
      try {
        this.workCreationInProgress = true;

        const result = await work.create({
          // name: this.newJobName!='' ? this.newJobName : this.newJobDescription.substring(0,255),
          name: '.',
          description: this.newJobDescription,
          // project_id: this.newJobProjectId.id,
          task_id:  this.taskId,
          project_id:  this.projectId,
          duration_minutes: this.newJobDurationMinutes,
          client_duration_minutes: this.newJobDurationMinutesClient>0 ? this.newJobDurationMinutesClient : this.newJobDurationMinutes,
          started_at: this.startDate,
          user_id: this.newJobAssigneeId,

          billable: true, // не должно быть обязательным
        });

        if (result.task_id) {
          console.log("work created: " + result.id);
        }

        this.$emit("ok");
        this.$emit("hide");
      } catch (error) {
        if (error.response?.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        // this.listWorks();
        this.workCreationInProgress = false;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
