<template>
  <q-layout view="hHh lpR lFf">
    <q-drawer show-if-above v-model="leftDrawerOpen" side="left" bordered
              :width="250"
              style="background-color: #F1F4F6; --background: linear-gradient(235deg, #558AE9, #51CBB9 100%);">
      <q-scroll-area style="height: calc(100% - 80px); margin-top: 80px;">
        <q-list>

          <q-separator/>

          <div class="q-pa-md" style="color: #2A2E34; font-weight: 700;" v-if="user && user.name">

            <q-btn flat no-caps class="q-pa-none">
              <div
                  style="background-color: #ccc; width: 30px; height: 30px; border-radius: 30px; display: flex; justify-content: center; align-items: center; margin-right: 10px">
                <q-img
                    v-if="user && user.settings.avatar"
                    :src="user.settings.avatar"
                    spinner-color="white"
                    style="width: 30px; height: 30px; border-radius: 100%;"
                />
                <span
                    v-else>{{user.name.split(' ').map(word => word.charAt(0)).join('')}}</span>
              </div>
              {{user.name}}
              <q-icon name="expand_more" />

              <q-menu fit>
                <q-list style="min-width: 100px">
                  <q-item clickable @click="onLogout">
                    <q-item-section>
                      <q-icon name="logout" />
                    </q-item-section>
                    <q-item-section class="q-pr-md">Выход</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </div>

          <q-separator/>


          <q-item clickable to="/notifications">
            <q-item-section avatar>
              <q-icon name="o_notifications" />
            </q-item-section>
            <q-item-section>Входящие</q-item-section>
            <q-item-section side>
              <q-badge rounded color="red" label="" v-if="user?.unreadCount>0">{{user.unreadCount}}</q-badge>
            </q-item-section>
          </q-item>

          <q-item clickable to="/tasks" v-if="user && [1,3].includes(user.id)">
            <q-item-section avatar>
              <q-icon name="o_list" />
            </q-item-section>
            <q-item-section>Задачи (отчет)</q-item-section>
          </q-item>

          <q-item clickable to="/timer" v-if="user && [1,3,9,10].includes(user.id)">
            <q-item-section avatar>
              <q-icon name="o_schedule" />
            </q-item-section>
            <q-item-section>Таймер</q-item-section>
          </q-item>

<!--          <q-expansion-item
              expand-separator
              icon="folder"
              label="Проекты"
              default-opened
              :content-inset-level="0"
          >
          </q-expansion-item>-->

          <q-item clickable to="bills">
            <q-item-section avatar>
              <q-icon name="o_receipt_long" />
            </q-item-section>
            <q-item-section>Счета</q-item-section>
          </q-item>

          <q-item>
<!--            <q-item-section avatar>-->
<!--              <q-icon name="o_folder" />-->
<!--            </q-item-section>-->
            <q-item-section>Проекты</q-item-section>
          </q-item>

          <q-item dense clickable v-for="project in projectList" :to="'/projects/'+project.id">
            <q-item-section avatar>
              <div
                  :style="`background-color: ${getProjectColor(project.settings ?? null)}; width: 20px; height: 20px; border-radius: 3px; display: flex; justify-content: center; align-items: center`">
                <q-icon
                    v-if="project.settings && JSON.parse(project.settings).icon_name"
                    :name="JSON.parse(project.settings).icon_name" style="color: #333333;" size="xs"/>
                <span
                    v-else>{{project.name.charAt(0)}}</span>
              </div>
            </q-item-section>
            <q-item-section>{{project.name}}</q-item-section>
          </q-item>

<!--          <q-item clickable to="/clients" v-if="user && user.allow.client.create">
            <q-item-section avatar>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_46_166)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M21 3.66667C21 2.74619 20.259 2 19.3448 2H4.65517C3.74105 2 3 2.74619 3 3.66667V20.3333C3 21.2538 3.74105 22 4.65517 22H19.3448C20.259 22 21 21.2538 21 20.3333V3.66667ZM15.4138 8.66667C15.4138 10.5076 13.9317 12 12.1034 12C10.2752 12 8.7931 10.5076 8.7931 8.66667C8.7931 6.82572 10.2752 5.33333 12.1034 5.33333C13.9317 5.33333 15.4138 6.82572 15.4138 8.66667ZM5.48276 16.1667C5.48276 13.95 9.89379 12.8333 12.1034 12.8333C14.3131 12.8333 18.7241 13.95 18.7241 16.1667V17C18.7241 17.9205 17.9831 18.6667 17.069 18.6667H7.13793C6.2238 18.6667 5.48276 17.9205 5.48276 17V16.1667Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_46_166">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </q-item-section>
            <q-item-section>Клиенты</q-item-section>
          </q-item>-->
<!--            <q-item clickable to="team" v-if="user && [1,3].includes(user.id)">
              <q-item-section avatar>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_46_151)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.67 13.13C18.04 14.06 19 15.32 19 17V20H23V17C23 14.82 19.43 13.53 16.67 13.13Z" fill="white"/>
                    <path d="M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4C14.53 4 14.09 4.1 13.67 4.24C14.5 5.27 15 6.58 15 8C15 9.42 14.5 10.73 13.67 11.76C14.09 11.9 14.53 12 15 12Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 13C6.33 13 1 14.34 1 17V20H17V17C17 14.34 11.67 13 9 13Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_46_151">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </q-item-section>
              <q-item-section>Команда</q-item-section>
            </q-item>-->
<!--          <q-item clickable to="tags">
            <q-item-section avatar>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 7C5.10218 7 4.72064 6.84196 4.43934 6.56066C4.15804 6.27936 4 5.89782 4 5.5C4 5.10218 4.15804 4.72064 4.43934 4.43934C4.72064 4.15804 5.10218 4 5.5 4C5.89782 4 6.27936 4.15804 6.56066 4.43934C6.84196 4.72064 7 5.10218 7 5.5C7 5.89782 6.84196 6.27936 6.56066 6.56066C6.27936 6.84196 5.89782 7 5.5 7ZM21.41 11.58L12.41 2.58C12.05 2.22 11.55 2 11 2H4C2.89 2 2 2.89 2 4V11C2 11.55 2.22 12.05 2.59 12.41L11.58 21.41C11.95 21.77 12.45 22 13 22C13.55 22 14.05 21.77 14.41 21.41L21.41 14.41C21.78 14.05 22 13.55 22 13C22 12.44 21.77 11.94 21.41 11.58Z" fill="white"/>
              </svg>
            </q-item-section>
            <q-item-section>Метки</q-item-section>
          </q-item>-->

          <q-separator/>

          <q-item clickable to="/projects" v-if="user && user.settings.allow?.project?.create">
            <q-item-section avatar>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_46_169)">
                  <path d="M20 6H12L10 4H4C2.9 4 2 4.9 2 6V8.5H22V8C22 6.9 21.1 6 20 6Z" fill="white"/>
                  <path d="M22 10.5H2V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V10.5Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_46_169">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </q-item-section>
            <q-item-section>Проекты (все, временно)</q-item-section>
          </q-item>

          <q-item clickable to="/statuses" v-if="user && user.settings.allow?.project?.create">
            <q-item-section avatar>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
            </q-item-section>
            <q-item-section>Статусы (все, временно)</q-item-section>
          </q-item>

<!--          <q-separator/>
          <q-separator/>
          <q-separator/>

          <q-item clickable @click="onLogout" style="opacity: 0.5">
            <q-item-section avatar>
              <q-icon name="logout" />
            </q-item-section>
            <q-item-section>Выход</q-item-section>
          </q-item>-->
        </q-list>
      </q-scroll-area>

      <div class="absolute-top q-pl-md" style="height: 80px; display: flex; align-items: center; justify-content: flex-start; --background-color: rgba(255,255,255, 0.2);">
          <svg width="24" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="512" height="512" rx="50" fill="url(#paint0_linear_523_130)"/>
            <path d="M259.619 136.656V367H208.045V176.682H120.4V367H68.9844V136.656H259.619ZM412.918 263.852V301.979H326.697V263.852H412.918ZM343.941 195.824V367H294.582V195.824H343.941ZM445.508 195.824V367H395.832V195.824H445.508Z" fill="white"/>
            <defs>
              <linearGradient id="paint0_linear_523_130" x1="512" y1="0" x2="0" y2="512" gradientUnits="userSpaceOnUse">
                <stop stop-color="#51CBB9"/>
                <stop offset="1" stop-color="#558AE9"/>
              </linearGradient>
            </defs>
          </svg>

          <div class="the-name" style="margin-left: 10px;">Понедельник</div>
      </div>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { project } from '@/api/project'
import { workspace } from '@/api/workspace'

export default {
  data() {
    return {
      projectList: [],
      leftDrawerOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      user: "account/user",
    }),
  },
  created() {
    this.fetchProjects();
    this.fetchWorkspace();
  },
  watch: {
  },
  methods: {
    getProjectColor(projectSettings) {
      if(projectSettings && JSON.parse(projectSettings).color) {
        return JSON.parse(projectSettings).color;
      } else {
        return '#7c828d';
      }
    },
    async fetchProjects() {
      try {
        const result = await project.list();

        // this.projectList = result;

        if (![1,3].includes(this.user.id) && this.user.settings.allow?.project?.allowIds) {
          this.projectList = result.filter(obj => {
            return this.user.settings.allow?.project?.allowIds.includes(obj.id);
          });
        } else {
          this.projectList = result;
        }
      } catch (error) {
        if (error.response?.status !== 404) {}
      } finally {}
    },
    ...mapActions({
      // accessToken: store.state.account.token,
      logout: 'account/logout',
    }),
    async fetchWorkspace() {
      try {
        const result = await workspace.getOne({
          id: 1,
        });

        console.log(result)
      } catch (error) {
        if (error.response?.status !== 404) {}
      } finally {}
    },
    ...mapActions({
      // accessToken: store.state.account.token,
      logout: 'account/logout',
    }),
    onLogout() {
      this.logout();
      this.$router.push({ name: 'login' });
    },
  }
};
</script>

<style lang="scss" scoped>
.q-drawer {
  min-height: calc(100% - 50px);
  height: fit-content !important;
}
:deep .q-item {
  color: #2A2E34;

  .q-item__section--avatar {
    min-width: unset;
  }

  &.q-item.q-router-link--active,
  &.q-item--active {
    background-color: #d9e1ec;
    color: #323944;
  }
}

.the-name {
  font-weight: 700;
  color: #2A2E34;
  font-size: 21px;
}
</style>
