
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: "account/user",
    }),
  },
  install: (app) => {
    app.config.globalProperties.$settings = {
      userList: [
        {
          id: 1,
          name: 'Алексей',
          email: 'alexei@vponedelnik.ru',
          avatar: '/temp/user-avatar-alexeih.jpg',
          allow: {
            project: {
              menuTemp: true,
              create: true,
              delete: true,
              edit: true,
            },
            task: {
              menuTemp: true,
              create: true,
              delete: true,
              edit: true,
            },
            client: {
              create: true,
            },
          },
        },
        {
          id: 2,
          name: 'Маша',
          email: 'masharapoport@gmail.com',
          avatar: '/temp/user-avatar-masharapoport.jpg',
          allow: {
            project: {
              create: true,
              delete: false,
              allowIds: [1]
            },
            task: {
              menuTemp: false,
              create: true,
              delete: true,
              edit: true,
            },
            client: {
              create: false,
            },
          },
        },
        {
          id: 3,
          name: 'Стас.',
          email: 'stas@vponedelnik.ru',
          avatar: '/temp/user-avatar-stas.png',
          allow: {
            project: {
              create: true,
              delete: true,
            },
            task: {
              delete: true,
            },
          },
        },
        {
          id: 6,
          name: 'Мария',
          email: 'eiffel75.ngs@gmail.com',
          avatar: '/temp/user-avatar-maria-mechkova.jpg',
          allow: {
            project: {
              menuTemp: false,
              create: false,
              delete: false,
              edit: false,
              allowIds: [7,8,10]
            },
            task: {
              menuTemp: false,
              create: true,
              delete: true,
              edit: true,
            },
            client: {
              create: false,
            },
          },
        },
        {
          id: 8,
          name: 'Александра Жосан',
          email: 'azhosan.cto@yandex.ru',
          avatar: '/temp/user-avatar-azhosan.jpg',
          allow: {
            project: {
              menuTemp: false,
              create: false,
              delete: false,
              edit: false,
              allowIds: [6,7]
            },
            users: {
              allowIds: [3,6,8],
            },
            task: {
              menuTemp: false,
              create: true,
              delete: true,
              edit: true,
            },
            client: {
              create: false,
            },
          },
        },
        {
          id: 9,
          name: 'Юра',
          email: 'jurchikstep@yandex.ru',
          avatar: '/temp/user-avatar-jur.jpg',
          allow: {
            project: {
              menuTemp: false,
              create: false,
              delete: false,
              edit: false,
              allowIds: [5,9,11]
            },
            task: {
              menuTemp: false,
              create: true,
              delete: true,
              edit: true,
            },
            client: {
              create: false,
            },
          },
        },
        {
          id: 10,
          name: 'Илья',
          email: 'faring2@yandex.ru',
          avatar: '/temp/user-avatar-ilya.jpg',
          allow: {
            project: {
              menuTemp: false,
              create: false,
              delete: false,
              edit: false,
              allowIds: [9,11]
            },
            task: {
              menuTemp: false,
              create: true,
              delete: true,
              edit: true,
            },
            client: {
              create: false,
            },
          },
        },
        {
          id: 11,
          name: 'Антон Иванов',
          email: 'china.spta@mail.ru',
          avatar: '/temp/user-avatar-china.jpg',
          allow: {
            project: {
              menuTemp: false,
              create: false,
              delete: false,
              edit: false,
              allowIds: [5,10,11,12,13],
            },
            users: {
              allowIds: [3,6,9,10,11,12],
            },
            task: {
              menuTemp: false,
              create: true,
              delete: false,
              edit: true,
            },
          },
        },
        {
          id: 12,
          name: 'Максим Будунов',
          email: 'budunov.m@autoleader1.ru',
          // avatar: '/temp/user-avatar-budunov.jpg',
          allow: {
            project: {
              menuTemp: false,
              create: false,
              delete: false,
              edit: false,
              allowIds: [11,12],
            },
            users: {
              allowIds: [3,6,9,10,11,12],
            },
            task: {
              menuTemp: false,
              create: true,
              delete: false,
              edit: true,
            },
          },
        },
      ],
      // в црм к этой функции обращались за данными так: const user = this.$settings.parseJwt(result.accessToken).data;
      parseJwt() {
        let base64Url = token.split(".")[1];
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        let jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        return JSON.parse(jsonPayload);
      },

      isSuperadmin() {
        return [1,3].includes(this.user.id)
      },

      getPriorityFlagColor(colorStr) {
        switch (colorStr) {
          case 'urgent':
            return {
              color: 'red-7',
              name: 'Срочный'
            };

          case 'high':
            return {
              color: 'yellow-8',
              name: 'Высокий'
            };

          case 'normal':
            return {
              color: 'blue-6',
              name: 'Обычный'
            };

          case 'low':
            return {
              color: 'grey-5',
              name: 'Низкий'
            };
        }
        return false;
      },

      getInvoiceStatusColor(colorStr) {
        switch (colorStr) {
          case 'new':
            return {
              color: 'blue-6',
              name: 'Новый'
            };

          case 'invoiced':
            return {
              color: 'yellow-8',
              name: 'Выставлен'
            };

          case 'paid':
            return {
              color: 'grey-5',
              name: 'Оплачен'
            };
        }
        return false;
      },

      // limit: 20,
      // openCommentsModal(comments) {
      //   return Dialog.create({
      //     component: CommentsModal,
      //     componentProps: {
      //       comments,
      //     },
      //   });
      // },
      // onNotify(message, color = "blue") {
      //   return Notify.create({
      //     color: color,
      //     message: message,
      //     position: "top",
      //     textColor: "white",
      //     actions: [{ icon: "close", color: "white", padding: "none" }],
      //   });
      // },
      // statuses: [
      //   {
      //     name: "Создан",
      //     value: "created",
      //   },
      //   {
      //     name: "Новый",
      //     value: "new",
      //   },
      //   {
      //     name: "Отменен",
      //     value: "canceled",
      //   },
      //   {
      //     name: "Подтвержден",
      //     value: "confirmed",
      //   },
      //   {
      //     name: "Не оплачен",
      //     value: "unpaid",
      //   },
      //   {
      //     name: "Частично оплачен",
      //     value: "partial-paid",
      //   },
      //   {
      //     name: "Оплачен",
      //     value: "paid",
      //   },
      //   {
      //     name: "Готов к отправке",
      //     value: "ready_for_take",
      //   },
      //   {
      //     name: "Выполнен",
      //     value: "completed",
      //   },
      // ],
      // openCommentModal(context, comment, hash) {
      //   return Dialog.create({
      //     component: CommentModal,
      //     componentProps: {
      //       comment,
      //       hash,
      //     },
      //   }).onOk(() => {
      //     context.$emit("change");
      //   });
      // },
      // showErrorMessage() {
      //   return; //
      //   app.config.globalProperties.$q.notify({
      //     color: "negative",
      //     message: "При загрузке данных произошла ошибка!",
      //     icon: "report_problem",
      //     position: "bottom",
      //   });
      // },
      // parseJwt(token) {
      //   let base64Url = token.split(".")[1];
      //   let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      //   let jsonPayload = decodeURIComponent(
      //     window
      //       .atob(base64)
      //       .split("")
      //       .map(function (c) {
      //         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      //       })
      //       .join("")
      //   );
      //
      //   return JSON.parse(jsonPayload);
      // },
    };
  },
};
